var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full data-table scrollx" },
    [
      _c(
        "vs-table",
        _vm._g(
          _vm._b(
            {
              model: {
                value: _vm.selectedItems,
                callback: function($$v) {
                  _vm.selectedItems = $$v
                },
                expression: "selectedItems"
              }
            },
            "vs-table",
            _vm.tableBindings,
            false
          ),
          _vm.tableEvents
        ),
        [
          _c(
            "template",
            { slot: "header" },
            [
              _vm.settings.actions && _vm.settings.actions.create
                ? _c("vs-button", { on: { click: _vm.create } }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("shops.create")))])
                  ])
                : _vm._e(),
              _vm.settings.pagination
                ? [
                    _c(
                      "div",
                      { staticClass: "ml-2 per-page-filter" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(_vm._s(_vm.$t("shops.per_page")) + ":")
                        ]),
                        _c(
                          "vs-select",
                          {
                            model: {
                              value: _vm.settings.pagination.itemsPerPage,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.settings.pagination,
                                  "itemsPerPage",
                                  $$v
                                )
                              },
                              expression: "settings.pagination.itemsPerPage"
                            }
                          },
                          _vm._l(
                            _vm.settings.pagination.itemsPerPageOptions,
                            function(item) {
                              return _c("vs-select-item", {
                                key: item,
                                attrs: { value: item, text: item }
                              })
                            }
                          ),
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              [
                _c(
                  "div",
                  { staticClass: "flex mt-1" },
                  [
                    _vm.exportRequest
                      ? _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'"
                            }
                          ],
                          staticClass: "border-grey-light",
                          attrs: {
                            name: "emailExport",
                            placeholder: _vm.$t("shops.email_export")
                          },
                          model: {
                            value: _vm.emailExport,
                            callback: function($$v) {
                              _vm.emailExport = $$v
                            },
                            expression: "emailExport"
                          }
                        })
                      : _vm._e(),
                    _vm.exportRequest
                      ? _c(
                          "vs-button",
                          {
                            staticClass: "p-2 ml-2",
                            attrs: { icon: "save" },
                            on: {
                              click: function($event) {
                                return _vm.exportData("csv")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("CSV")))]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              _vm.settings.filters && _vm.settings.filters.dateRange
                ? [
                    _c(
                      "div",
                      { staticClass: "mb-6 date-range-filter" },
                      [
                        _c(
                          "div",
                          { staticClass: "ml-2" },
                          [
                            _c("label", { staticClass: "vs-input--label" }, [
                              _vm._v(_vm._s(_vm.$t("shops.from_date")))
                            ]),
                            _c("datepicker", {
                              attrs: {
                                inline: false,
                                mondayFirst: true,
                                name: "dateRangeFromDate"
                              },
                              model: {
                                value: _vm.dateRangeFilter.fromDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.dateRangeFilter, "fromDate", $$v)
                                },
                                expression: "dateRangeFilter.fromDate"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "ml-2" },
                          [
                            _c("label", { staticClass: "vs-input--label" }, [
                              _vm._v(_vm._s(_vm.$t("shops.to_date")))
                            ]),
                            _c("datepicker", {
                              attrs: {
                                inline: false,
                                mondayFirst: true,
                                name: "dateRangeToDate"
                              },
                              model: {
                                value: _vm.dateRangeFilter.toDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.dateRangeFilter, "toDate", $$v)
                                },
                                expression: "dateRangeFilter.toDate"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              disabled: _vm.isDateRangeDisabled,
                              type: "filled"
                            },
                            on: { click: _vm.onDateRangeSubmit }
                          },
                          [_vm._v(_vm._s(_vm.$t("shops.filter")))]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              disabled: _vm.isDateRangeDisabled,
                              type: "border"
                            },
                            on: { click: _vm.onDateRangeClear }
                          },
                          [_vm._v(_vm._s(_vm.$t("shops.clear")))]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.settings.actions && _vm.settings.actions.export
                ? [
                    _c(
                      "vs-button",
                      {
                        staticClass: "ml-2",
                        attrs: { type: "filled" },
                        on: { click: _vm.onExportSubmit }
                      },
                      [_vm._v(_vm._s(_vm.$t("Export (XSL)")))]
                    )
                  ]
                : _vm._e(),
              _vm._t("header")
            ],
            2
          ),
          _c(
            "template",
            { slot: "thead" },
            [
              _vm._l(_vm.tableHeaders, function(th, index) {
                return _c(
                  "vs-th",
                  { key: _vm.getKey(th, index), attrs: { "sort-key": th } },
                  [_vm._v(_vm._s(_vm._f("table_title")(_vm.$t(th))))]
                )
              }),
              _vm.isActionsShown
                ? _c("vs-th", [_vm._v(_vm._s(_vm.$t("shops.actions")))])
                : _vm._e()
            ],
            2
          ),
          _vm._l(_vm.data, function(tr, index) {
            return _c(
              "vs-tr",
              { key: _vm.getKey(tr, index), attrs: { data: tr } },
              [
                _vm._l(tr, function(td, index) {
                  return _c(
                    "vs-td",
                    { key: _vm.getKey(td, index), attrs: { data: td } },
                    [
                      typeof td === "boolean"
                        ? _c("vs-checkbox", {
                            attrs: { checked: td, disabled: "true" }
                          })
                        : _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.table_format(
                                  td,
                                  index,
                                  _vm.$route.params.uuid,
                                  _vm.$route.name
                                )
                              )
                            }
                          })
                    ],
                    1
                  )
                }),
                _vm.isActionsShown
                  ? [
                      _c("vs-td", [
                        _c(
                          "div",
                          { staticClass: "actions-buttons w-36 float_left" },
                          [
                            tr.external_id && new Date(tr.date) > new Date()
                              ? [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "float-left mr-1",
                                      attrs: {
                                        icon: "check",
                                        size: "large",
                                        type: "border",
                                        title: _vm.$t("Cancel")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onConfirmClicked(tr)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("shops.confirm")))]
                                  ),
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "float-left mr-1",
                                      attrs: {
                                        icon: "cancel",
                                        size: "large",
                                        title: _vm.$t("Cancel")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onCancelClicked(tr)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("shops.cancel")))]
                                  )
                                ]
                              : _vm._e(),
                            _vm.settings.actions.select
                              ? [
                                  _c("vs-button", {
                                    staticClass: "float-left mr-1",
                                    attrs: { icon: "search", size: "large" },
                                    on: {
                                      click: function($event) {
                                        return _vm.select(tr)
                                      }
                                    }
                                  })
                                ]
                              : _vm._e(),
                            _vm.settings.actions.update
                              ? [
                                  _c("vs-button", {
                                    staticClass: "float-left mr-1",
                                    attrs: {
                                      icon: "create",
                                      size: "large",
                                      type: "border"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.update(tr)
                                      }
                                    }
                                  })
                                ]
                              : _vm._e(),
                            _vm.settings.actions.delete
                              ? [
                                  _c("vs-button", {
                                    staticClass: "float-left mr-1",
                                    attrs: { icon: "delete", size: "large" },
                                    on: {
                                      click: function($event) {
                                        return _vm.remove(tr)
                                      }
                                    }
                                  })
                                ]
                              : _vm._e(),
                            _vm.settings.actions.custom
                              ? [_vm._t("actions", null, { data: tr })]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          })
        ],
        2
      ),
      _vm.settings.pagination && _vm.isPaginationShown
        ? [
            _c(
              "div",
              { staticClass: "m-2" },
              [
                _c("vs-pagination", {
                  staticClass: "mt-base",
                  attrs: { total: _vm.settings.pagination.totalPages },
                  model: {
                    value: _vm.settings.pagination.currentPage,
                    callback: function($$v) {
                      _vm.$set(_vm.settings.pagination, "currentPage", $$v)
                    },
                    expression: "settings.pagination.currentPage"
                  }
                })
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }