<template>
  <div class="w-full data-table scrollx">
    <vs-table v-bind="tableBindings" v-on="tableEvents" v-model="selectedItems">
      <template slot="header">
        <vs-button v-if="settings.actions && settings.actions.create" @click="create">
          <span>{{ $t("shops.create")}}</span>
        </vs-button>

        <!-- Items per page selector -->
        <template v-if="settings.pagination">
          <div class="ml-2 per-page-filter">
            <label class="vs-input--label">{{$t("shops.per_page")}}:</label>
            <vs-select v-model="settings.pagination.itemsPerPage">
              <vs-select-item v-for="item in settings.pagination.itemsPerPageOptions" :key="item" :value="item" :text="item" />
            </vs-select>
          </div>
        </template>

        <template>
          <div class="flex mt-1">
            <vs-input v-if="exportRequest" class="border-grey-light" v-model="emailExport" name="emailExport" v-validate="'required|email'" :placeholder="$t('shops.email_export')" />
            <vs-button v-if="exportRequest" class="p-2 ml-2" @click="exportData('csv')" icon="save">{{$t("CSV")}}</vs-button>
          </div>
        </template>

        <!-- Date range filter -->
        <template v-if="settings.filters && settings.filters.dateRange">
          <div class="mb-6 date-range-filter">
            <div class="ml-2">
              <label class="vs-input--label">{{ $t("shops.from_date") }}</label>
              <datepicker :inline="false" :mondayFirst="true" v-model="dateRangeFilter.fromDate" name="dateRangeFromDate"></datepicker>
            </div>

            <div class="ml-2">
              <label class="vs-input--label">{{ $t("shops.to_date") }}</label>
              <datepicker :inline="false" :mondayFirst="true" v-model="dateRangeFilter.toDate" name="dateRangeToDate"></datepicker>
            </div>

            <vs-button :disabled="isDateRangeDisabled" @click="onDateRangeSubmit" class="ml-2" type="filled">{{ $t("shops.filter") }}</vs-button>
            <vs-button :disabled="isDateRangeDisabled" @click="onDateRangeClear" class="ml-2" type="border">{{ $t("shops.clear") }}</vs-button>
          </div>
        </template>

        <!-- Export -->
        <template v-if="settings.actions && settings.actions.export">
          <vs-button @click="onExportSubmit" class="ml-2" type="filled">{{ $t("Export (XSL)") }}</vs-button>
        </template>

        <!-- Header Slot -->
        <slot name="header"></slot>
      </template>

      <!-- Headers -->
      <template slot="thead">
        <vs-th v-for="(th, index) in tableHeaders" :key="getKey(th, index)" :sort-key="th">{{ $t(th) | table_title }}</vs-th>
        <vs-th v-if="isActionsShown">{{ $t("shops.actions") }}</vs-th>
      </template>

      <template>
        <vs-tr :data="tr" v-for="(tr, index) in data" :key="getKey(tr, index)">
          <!-- Data render -->
          <vs-td :data="td" v-for="(td, index) in tr" :key="getKey(td, index)">
            <vs-checkbox v-if="typeof(td) === 'boolean'" :checked="td" disabled="true"></vs-checkbox>
            <span v-else v-html="$options.filters.table_format(td, index, $route.params.uuid, $route.name)"></span>
          </vs-td>

          <!-- Action buttons -->
          <template v-if="isActionsShown">
            <vs-td>
              <div class="actions-buttons w-36 float_left">
                <template v-if="tr.external_id && new Date(tr.date) > new Date()">
                  <vs-button
                  @click="onConfirmClicked(tr)"
                  class="float-left mr-1"
                  icon="check"
                  size="large"
                  type="border"
                  :title="$t('Cancel')"
                >{{$t('shops.confirm')}}</vs-button>
                  <vs-button
                  @click="onCancelClicked(tr)"
                  class="float-left mr-1"
                  icon="cancel"
                  size="large"
                  :title="$t('Cancel')"
                >{{$t('shops.cancel')}}</vs-button>
                </template>
                <template v-if="settings.actions.select">
                  <vs-button @click="select(tr)" class="float-left mr-1" icon="search" size="large"></vs-button>
                </template>
                <template v-if="settings.actions.update">
                  <vs-button @click="update(tr)" class="float-left mr-1" icon="create" size="large" type="border"></vs-button>
                </template>
                <template v-if="settings.actions.delete">
                  <vs-button @click="remove(tr)" class="float-left mr-1" icon="delete" size="large"></vs-button>
                </template>

                <template v-if="settings.actions.custom">
                  <slot name="actions" :data="tr"></slot>
                </template>
              </div>
            </vs-td>
          </template>
        </vs-tr>
      </template>
    </vs-table>

    <!-- Pagination -->
    <template v-if="settings.pagination && isPaginationShown">
      <div class="m-2">
        <vs-pagination class="mt-base" :total="settings.pagination.totalPages" v-model="settings.pagination.currentPage" />
      </div>
    </template>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import notifications from "@/modules/Shared/Mixins/notifications";
import { shopapi } from "@/axios.js";
import i18n from "@/i18n";
import { mapGetters } from 'vuex';

const version = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;

// All the events emited by the data-table
const EVENTS = {
  selected: "selected",
  create: "create",
  remove: "remove",
  update: "update",
  export: "export",
  sort: "sort",
  search: "search",
  changePage: "change-page",
  perPageChange: "per-page-change",
  dateRangeSearch: "date-range-search",
  dateRangeClear: "date-range-clear",
};

const defaultActions = () => ({
  select: false,
  create: true,
  update: true,
  delete: true,
  export: false,
  custom: false,
});

const defaultFilters = () => ({
  search: true,
  searchTimeoutMs: 1000,
  sort: true,
  dateRange: false,
});

const defaultPagination = () => ({
  currentPage: 1,
  totalPages: 1,
  itemsPerPage: 10,
  itemsPerPageOptions: [5, 10, 15, 50],
});

const defaultOptions = () => ({
  actions: defaultActions(),
  filters: defaultFilters(),
  pagination: defaultPagination(),
  sst: true,
  stripe: false,
});

const DEFAULT_FROM_DATE = new Date(0).toISOString().substring(0, 10);
const DEFAULT_TO_DATE = `${new Date().toISOString().substring(0, 10)} 23:59:59`;
const apiVersion = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;

export default {
  name: "data-table",

  mixins: [notifications],

  props: {
    data: { type: Array, required: true },
    exportRequest: {type: Object},
    options: { type: Object, default: () => defaultOptions() },
    // @DEPRECATED: Use the "options" prop instead
    currentPage: { type: Number, default: () => 1 },
    // @DEPRECATED: Use the "options" prop instead
    totalPages: { type: Number, default: () => 1 },
    // @DEPRECATED: Use the "options" prop instead
    itemsPerPage: { type: Number, default: () => 10 },
    // @DEPRECATED: Use the "options" prop instead
    createButton: { type: Boolean, default: () => true },
    // @DEPRECATED: Use the "options" prop instead
    updateButton: { type: Boolean, default: () => true },
    // @DEPRECATED: Use the "options" prop instead
    deleteButton: { type: Boolean, default: () => true },
    // @DEPRECATED: Use the "options" prop instead
    itemsPerPageArray: { type: Array, default: () => [5, 10, 15, 50] },
    // @DEPRECATED: Use the "options" prop instead
    showPagination: { type: Boolean, default: () => true },
    // @DEPRECATED: Use the "options" prop instead
    showPerPage: { type: Boolean, default: () => true },
    // @DEPRECATED: Use the "options" prop instead
    search: { type: Boolean, default: () => true },
  },

  components: {
    Datepicker,
  },

  data() {
    return {
      settings: {
        actions: defaultActions(),
        filters: defaultFilters(),
        pagination: defaultPagination(),
        sst: true,
        stripe: false,
      },
      selectedItems: [],

      // The event created by "setTimeout()"
      searchTimeout: null,

      dateRangeFilter: {
        isSearchEventEmited: false,
        fromDate: "",
        toDate: "",
      },

      emailExport: ''
    };
  },

  beforeMount() {
    // Copy $props.options to internal property to avoid prop mutation
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Merging_objects_with_same_properties

    // For backwards compatibility
    Object.assign(this.settings.actions, {
      create: this.$props.createButton,
      update: this.$props.updateButton,
      delete: this.$props.deleteButton,
    });

    // If options.actions is supplied, it overrides the other props
    if (this.$props.options.actions === false) {
      this.settings.actions = false;
    } else {
      Object.assign(this.settings.actions, this.$props.options.actions);
    }

    Object.assign(this.settings.filters, {
      search: this.$props.search,
    });

    if (this.$props.options.filters === false) {
      this.settings.filters = false;
    } else {
      Object.assign(this.settings.filters, this.$props.options.filters);
    }

    // For backwards compatibility
    Object.assign(this.settings.pagination, {
      currentPage: this.$props.currentPage,
      totalPages: this.$props.totalPages,
      itemsPerPage: this.$props.itemsPerPage,
      itemsPerPageOptions: this.$props.itemsPerPageArray,
    });

    // If options.pagination is supplied, it overrides the other props
    if (this.$props.options.pagination === false) {
      this.settings.pagination = false;
    } else {
      Object.assign(this.settings.pagination, this.$props.options.pagination);
    }
  },

  computed: {
    ...mapGetters(['applicationLanguage']),
    tableBindings() {
      const bindings = {
        data: this.tableData,
        sst: this.settings.sst,
        stripe: this.settings.stripe,
        search: this.settings.filters && this.settings.filters.search,
      };

      if (
        this.settings.pagination &&
        this.settings.pagination.itemsPerPage > 0
      ) {
        bindings["max-items"] = this.settings.pagination.itemsPerPage;
      }

      return bindings;
    },

    tableHeaders() {
      if (this.tableData.length > 0) {
        return Object.keys(this.tableData[0]);
      }

      console.warn(
        `[${this.$options.name}]`,
        "Could not generate table headers"
      );
      return [];
    },

    tableData() {
      return this.$props.data;
    },

    tableEvents() {
      let eventBindings = {};

      if (this.settings.actions && this.settings.actions.search) {
        eventBindings.search = this.onSearch;
      }

      if (this.settings.filters) {
        if (this.settings.filters.search) {
          eventBindings.search = this.onSearch;
        }

        if (this.settings.filters.sort) {
          eventBindings.sort = this.onSort;
        }
      }

      return eventBindings;
    },

    isPaginationShown() {
      if (!this.settings.pagination) {
        return false;
      }

      if (this.settings.pagination.totalPages > 1) {
        return true;
      }

      if (this.showPagination) {
        return true;
      }

      return false;
    },

    isActionsShown() {
      if (!this.updateButton && !this.deleteButton) {
        return false;
      }

      if (this.settings.actions === false) {
        return false;
      }

      if (this.settings.actions.update || this.settings.actions.delete) {
        return true;
      }

      if (this.settings.actions.custom) {
        return true;
      }

      if (
        this.$slots &&
        this.$slots.actions &&
        this.$slots.actions.text.length > 0
      ) {
        return true;
      }

      if(this.settings.actions.cancelButton) {
        return true;
      }
    },

    isDateRangeDisabled() {
      return (
        this.dateRangeFilter.fromDate === "" &&
        this.dateRangeFilter.toDate === ""
      );
    },
  },

  methods: {
    getKey(obj, extra = null) {
      if (!obj) {
        return Math.random().toString(10);
      }

      if (extra) {
        return `${Object.keys(obj)[0]}-${extra}`;
      }

      return Object.keys(obj)[0];
    },

    create() {
      this.$emit(EVENTS.create);
    },

    update(payload) {
      this.$emit(EVENTS.update, payload);
    },

    remove(payload) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: i18n.t("shops.delete"),
        text: i18n.t("shops.are_you_sure_about_that?"),
        accept: () => this.$emit(EVENTS.remove, payload),
      });
    },

    select(event) {
      this.$emit(EVENTS.selected, event);
    },

    onSearch(event) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$emit(EVENTS.search, event);
      }, this.settings.filters.searchTimeoutMs);
    },

    onSort(key, order) {
      this.$emit(EVENTS.sort, { key, order });
    },

    onDateRangeSubmit() {
      let eventData = {
        from: DEFAULT_FROM_DATE,
        to: DEFAULT_TO_DATE,
      };

      // Check if date has been assigned
      if (this.dateRangeFilter.fromDate instanceof Date) {
        eventData.from = this.dateRangeFilter.fromDate
          .toISOString()
          .substring(0, 10);
      }

      if (this.dateRangeFilter.toDate instanceof Date) {
        eventData.to =
          this.dateRangeFilter.toDate.toISOString().substring(0, 10) +
          " 23:59:59";
      }

      // Don't emit if fields are defaults
      if (
        eventData.from === DEFAULT_FROM_DATE &&
        eventData.to === DEFAULT_TO_DATE
      ) {
        return;
      }

      this.dateRangeFilter.isSearchEventEmited = true;
      this.$emit(EVENTS.dateRangeSearch, eventData);
    },

    onDateRangeClear() {
      // Don't emit if fields are already empty
      if (
        this.dateRangeFilter.fromDate === DEFAULT_FROM_DATE &&
        this.dateRangeFilter.toDate === DEFAULT_TO_DATE
      ) {
        return;
      }

      this.dateRangeFilter.fromDate = "";
      this.dateRangeFilter.toDate = "";

      // If the "search" event hasn't been emited, don't send the clear event
      if (!this.dateRangeFilter.isSearchEventEmited) {
        // Reset the flag for next time
        this.dateRangeFilter.isSearchEventEmited = false;
        return;
      }

      this.$emit(EVENTS.dateRangeClear);
    },

    onExportSubmit() {
      this.$emit(EVENTS.export);
    },

    async exportData(type) {
      this.$validator.validate('emailExport').then(async (result) => {
        if (result) {
          this.$vs.loading();
          try {
            const response = await shopapi.get(
              `/api/${version}/${this.exportRequest.request}`,
              {
                params: {
                  ...this.exportRequest.payload,
                  email_export: this.emailExport,
                  type: type,
                  language: this.applicationLanguage
                }
              }
            )

            if (response.status === 200) {
              this.notifySuccess(i18n.t("shops.success"), i18n.t("shops.email_export_sent", { email: this.emailExport }));
            }

            this.$vs.loading.close();
          } catch (e) {
            this.notifyError(
              i18n.t("shops.error.0"),
              i18n.t("shops.errors.there_was_an_error_while_trying_to_export_please_try_again_later")
            )
            this.$vs.loading.close();
          }
        } else {
          this.$vs.notify({
            text: this.$t("shops.email_export_needed"),
            color: 'danger',
            position:'top-right'
          })
        }
      });
    },

    async onConfirmClicked(data) {
      try {
        const response = await shopapi.post(
          `/api/${apiVersion}/order_details/${data.id}/confirm`
        );

        if (response.status === 200) {
          this.notifySuccess(i18n.t("shops.success"), i18n.t("shops.the_product_was_successfuly_confirmed"));
          const index = this.orderIndexById(data.id);

          if (index !== -1) {
            this.$store.commit(
              "shops/order_details/SET_BY_INDEX",
              { index, resource: response.data },
              { root: true }
            );
          } else {
            console.warn(i18n.t("shops.failed_to_update_order_in_store"), index);
          }
        } else {
          this.notifyError(
            i18n.t("shops.error"),
            i18n.t("shops.errors.there_was_an_error_while_trying_to_confirm_please_try_again_later")
          );
        }
      } catch (e) {
        console.warn(e);
      }
    },

    async onCancelClicked(data) {
      try {
        const response = await shopapi.post(
          `/api/${apiVersion}/resorts/${this.$route.params.uuid}/order_details/${data.id}/cancel`
        );

        if (response.status === 200) {
          this.notifySuccess(i18n.t("shops.success"), i18n.t("shops.the_product_was_successfuly_canceled"));
          const index = this.orderIndexById(data.id);

          if (index !== -1) {
            this.$store.commit(
              "shops/order_details/SET_BY_INDEX",
              { index, resource: response.data },
              { root: true }
            );
          } else {
            console.warn(i18n.t("shops.failed_to_update_order_in_store"), index);
          }
        } else {
          this.notifyError(
            i18n.t("shops.error"),
            i18n.t("shops.errors.there_was_an_error_while_trying_to_cancel_please_try_again_later")
          );
        }
      } catch (e) {
        console.warn(e);
      }
    },
    checkNested(element) {
      return Object.prototype.toString.call(element).indexOf("Object")>-1
    }
  },

  watch: {
    "settings.pagination.currentPage"(newValue) {
      this.$emit(EVENTS.changePage, newValue);
    },

    "settings.pagination.itemsPerPage"(newValue) {
      this.settings.pagination.currentPage = 1;
      this.$emit(EVENTS.perPageChange, newValue);
    },
    "options.pagination.totalPages"(newValue){
      this.settings.pagination.totalPages = newValue;
    }
  },
};
</script>

<style lang="scss">
.per-page-filter {
  margin-bottom: 28px;
  @apply mr-2;
}

.date-range-filter {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @apply ml-2;
}

.vs-table--header {
  @apply flex;
  @apply flex-row-reverse;
}

.vuesax-app-is-ltr.vs-table--search,
.con-input-search.vs-table--search {
  margin-left: 0.5rem;
}

.actions-buttons {
  min-width: 96px;
}

.vs-con-tbody {
  transform:rotateX(180deg);
  -ms-transform:rotateX(180deg); /* IE 9 */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
}

.vs-table {
  transform:rotateX(180deg);
  -ms-transform:rotateX(180deg); /* IE 9 */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
}
</style>
